import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 35;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <>
      <ul className="basicList">
        <li>健康意識が高まった一年</li>
        <li>貯金したものを即日使うタイプ</li>
        <li>屋根裏トークを始めたことで編集できるようになった</li>
        <li>一年前はトランプ大統領を応援してました</li>
        <li>事実じゃない方がおもしろい</li>
        <li>日本のメディアはすべてがニュートラル？</li>
        <li>ひろゆきを熱心に追いかけている人たち</li>
        <li>朝コンビニに新聞が届いていない</li>
        <li>新聞て万能で便利だよね</li>
        <li>田舎の嫌なところ</li>
        <li>朝はおいしいパンを食べたいよね</li>
        <li>田舎だと欲しいものが欲しいときに手に入らない</li>
        <li>孤独との戦いをしています</li>
        <li>心のサイクルが分かるようになってきた</li>
        <li>今年の前半はハイパーメディアクリエイター</li>
        <li>デジタルと自然を大切にしながら幸せとは何かを考える</li>
        <li>デジタル製品はすぐ古くなってしまう</li>
        <li>いいものを長く使いたい</li>
        <li>ローカルで資源とお金が廻るサイクル</li>
        <li>老後のことを考える</li>
      </ul>
    </>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeContent={episodeContent}
        episodeURL={episode.embed}
      />
    </Layout>
  );
};
